<template>
  <div class="table__container">
    <div class="d-flex">
      <b-button style="width: 6rem;border-radius: 0;" variant="primary" @click="$router.push({ name: 'welcome.index'})">
        <feather-icon
          icon="ChevronLeftIcon"
          class="font-large-1"
        />
      </b-button>
      <header-page>
        Keranjang
      </header-page>
    </div>

    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-1">
          <feather-icon
            icon="FilterIcon"
            size="18"
          />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari nama pembeli"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div
      v-if="isLoading == true"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table
      v-else
      :columns="columns"
      :rows="result"
      :fixed-header="false"
      :search-options="{
        enabled: false
      }"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field =='action'">
          Opsi
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

      <!-- Column: Action -->
      <span v-if="props.column.field === 'total'">
        <span>{{ props.row.total | formatAmount }} </span>
      </span>

      <!-- Column: Pembeli -->
      <span v-else-if="props.column.field === 'queue_name'">
        <span class="text-danger cursor-pointer" @click="getDetail(props.row.uuid)" >
          {{ props.row.queue_name || '-' }}
        </span>
      </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-1 px-1 border-0 bg-danger bg-accent-1"
                @click="deleteCart(props.row.uuid)"
              >
                <feather-icon
                  size="18"
                  class="text-danger"
                  icon="Trash2Icon"
                />
              </b-button>
              <b-button
                class="d-flex"
                @click="processCart(props.row.uuid)"
              >
                <feather-icon
                  class="mr-1"
                  icon="RefreshCwIcon"
                />
                <span>Proses</span>
              </b-button>
            </div>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalCart :cartDetail="cartDetail" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
/* eslint-disable no-restricted-syntax */
import HeaderPage from '@/components/HeaderPage.vue'
import ModalCart from '@/components/Cashier/Cart/ModalCart.vue'
import {
  BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BButton, BRow, BCol, BSpinner, BvComponent,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { errorNotification } from '@/auth/utils'

export default {
  title() {
    return 'Keranjang'
  },
  components: {
    HeaderPage,
    VueGoodTable,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BRow,
    BCol,
    BSpinner,
    ModalCart
  },
  setup() {
    return {
      errorNotification,
    }
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Antrian',
          field: 'queue_number',
        },
        {
          label: 'Pembeli',
          field: 'queue_name',
        },
        {
          label: 'Jumlah Belanja',
          field: 'total',
        },
        {
          label: 'Waktu & Tanggal',
          field: 'time',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      result: [],
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'asc',
      },
      isLoading: false,
      cartDetail: {},
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      await this.$store.dispatch('cart/getList', {
        params: queryParams,
      })
        .then(result => {
          this.rows = result.data.data
          this.result = result.data.data.data
          this.isLoading = false
        }).catch(err => {
          if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
          this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    async getDetail(id) {
      await this.$store.dispatch('cart/getCart', {
        uuid: id,
      })
        .then(result => {
          this.cartDetail = result.data.data
          console.log(this.cartDetail,'ini')
          this.$bvModal.show('modal-cart')
        }) .catch (err => {
          if(err.response.data.meta.messages){
            errorNotification(this, 'Oops!', err.response.data.meta.messages)
          }
        })
    },
    processCart(id) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin ingin memproses keranjang belanja ini?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ya, Proses!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Telah Diproses!',
            text: 'Keranjang belanja telah di proses.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(res => {
            if (res.value) {
              this.$router.push({ name: 'pos.index', query: { uuid: id } })
            }
          })
        }
      })
    },
    deleteCart(id) {
      this.$swal({
        title: 'Konfirmasi',
        text: "Apa Anda yakin ingin menghapus keranjang belanja ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('cart/deleteCart', {
            uuid: id,
          }).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Terhapus!',
              text: 'Keranjang berhasil dihapus.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.getData()
          }).catch(err => {
            // eslint-disable-next-line no-console
            console.log(err)
          })
        }
      })
    },
    select() {
      this.allSelected = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table {
  &.vgt-table {
    border: none;
  }
}

.vgt-responsive {
  height: calc(100vh - 236px) !important;
  background-color: #fff;
}
</style>
